<template>
<div class="CedantDashboard">
  <div class="container-fluid">
    <b-modal size="xl" ref="handleCandidatureModal" hide-footer>
      <template v-slot:modal-title>
        <strong> Fiche détaillée </strong>
      </template>

      <candidatureFicheDetaillee
        :candidature="handleCandidature"
      />

      <div v-if="handleCandidature.state != '3VALIDATED'" class="row mt-4">
        <div class="col-sm-6 text-center">
          <button :disabled="handleCandidature.state == '2REFUSED'" @click="treatCandidature('2REFUSED')" class="gavc-btn gavc-btn--bone">
            <i class="gavc-icon gavc-icon-cross"></i> Refuser cette candidature
          </button>
        </div>
        <div class="col-sm-6 text-center">
          <button :disabled="handleCandidature.state == '3VALIDATED'" @click="treatCandidature('3VALIDATED')" class="gavc-btn">
            <i class="gavc-icon gavc-icon-check"></i> Démarrer les pourparlers
          </button>
        </div>
      </div>
    </b-modal>
    <div class="row p-3">
      <div class="col-12 col-xl-6 offset-xl-1">
        <p class="tk-congenial">Bienvenue dans votre espace cédant</p>

        <h1 class="gavc-h1 tk-congenial mt-4">
          Vous êtes cédant,<br>
          Votre repreneur est bientôt là
        </h1>

        <p class="gavc-question mt-4">
          Avec Garance à vos côtés, céder votre entreprise n'a jamais été aussi simple ! <br>
          Gagnez en visibilité, trouvez le repreneur idéal et cédez au bon prix !
        </p>

        <p class="gavc-chapo" v-if="isCedantInformationCompleted">
          <i class="gavc-icon gavc-icon-check"></i> Félicitations vos informations sont complétées
        </p>

        <hr>

        <div style="margin-top: 50px;">

        </div>

        <div class="mt-4">
          <router-link
            class="gavc-btn"
            :class="{ 'gavc-btn--bone': cedant.completed_rate === 100}"
            to="/cedant/editer-mon-profil"
          >
            <i
              v-if="cedant.completed_rate === 100"
              class="gavc-icon gavc-icon-check-circle"
            />
            Editer mon profil
          </router-link>
        </div>

        <div v-if="!cedant.is_personne_physique" class="mt-4">
          <router-link
            :class="{ 'gavc-btn--bone': cedant.company.completed_rate === 100}"
            class="gavc-btn"
            to="/cedant/ma-societe"
          >
            <i
              v-if="cedant.company.completed_rate === 100"
              class="gavc-icon gavc-icon-check-circle"
            />
            Editer ma société
          </router-link>
        </div>

        <div class="mt-4">
          <router-link
            :class="{ 'gavc-btn--bone': cedant.entreprise_cedee.completed_rate === 100}"
            class="gavc-btn"
            to="/cedant/mon-entreprise-a-ceder"
          >
            <i
              v-if="cedant.entreprise_cedee.completed_rate === 100"
              class="gavc-icon gavc-icon-check-circle"
            />
            Editer mon entreprise à céder
          </router-link>
        </div>

        <div
          v-if="cedant.entreprise_cedee.offre_cession.annonce"
          class="mt-4"
        >
          <router-link
            class="mt-3 mt-md-0 gavc-btn"
            :class="{ 'gavc-btn--bone': cedant.entreprise_cedee.offre_cession.annonce.completed_rate === 100 }"
            :to="`/cedant/offre-cession/annonce/${cedant.entreprise_cedee.offre_cession.annonce.id}/edition`"
          >
            <i
              v-if="cedant.entreprise_cedee.offre_cession.annonce.completed_rate === 100"
              class="gavc-icon gavc-icon-check-circle"
            />
            Editer mon annonce
          </router-link>
        </div>

      </div>
      <div
        class="col-12 col-xl-5 mt-4 mt-xl-0"
      >
      <div class="row">
        <div class="col-12 col-xl-11">
          <div v-if="cedant.entreprise_cedee.offre_cession.annonce" class="gavc-card-offer gavc-padding-large">
            <div class="gavc-card-offer__header gavc-margin-bottom">
              <p class="gavc-question gavc-margin-bottom">
                profil complété à<span class="gavc-h1">{{tauxCompletion}}%</span>
              </p>
              <div class="gavc-card-progress-bar">
                <div class="gavc-card-progress-bar gavc-card-progress-bar--active" :style="`width: ${tauxCompletion}%;`">
                </div>
              </div>
            </div>
            <div class="gavc-card-offer__content gavc-margin-top gavc-padding-small">
              <div class="gavc-card-offer__content--header gavc-margin-bottom">
                <figure class="gavc-card-offer__content--header__img gavc-margin-right">
                  <img
                    v-if="cedant.entreprise_cedee.offre_cession.annonce.images.length > 0"
                    class="gavc-img-cover"
                    :src="imageUrl(cedant.entreprise_cedee.offre_cession.annonce.images[0])"
                    alt="image de l'annonce"
                  >
                  <img
                    v-else
                    class="gavc-img-cover"
                    :src="require('@/assets/images/default.jpg')"
                    alt="image de l'annonce"
                  >
                </figure>
                <div class="gavc-card-offer__content--header__infos">
                  <p class="gavc-title-offer gavc-margin-bottom">{{cedant.entreprise_cedee.name}} - {{cedant.entreprise_cedee.offre_cession.annonce.title}} </p>
                  <p class="gavc-title-price gavc-margin-bottom">
                    Offre :
                    <span
                      v-if="cedant.entreprise_cedee.offre_cession.annonce.price && cedant.entreprise_cedee.offre_cession.annonce.price > 0"
                    >
                      {{formatThousandSeparatorNumber(Number(cedant.entreprise_cedee.offre_cession.annonce.price).toFixed(0))}} €
                    </span>
                    <span
                      v-else-if="cedant.entreprise_cedee.offre_cession.annonce.price_category"
                    >
                      {{priceCategoryRender(cedant.entreprise_cedee.offre_cession.annonce.price_category)}}
                    </span>
                  </p>
                  <div class="gavc-card-offer__content--header__icon-text">
                    <i class="gavc-icon gavc-icon-pin"></i>{{cedant.entreprise_cedee.city}} (siège social)
                  </div>
                </div>
              </div>
              <div class="gavc-card-offer__content--desc gavc-text-small">
                <strong>Activité:</strong>
                <span v-if="cedant.entreprise_cedee.offre_cession.annonce.activite.length > 100">
                  {{cedant.entreprise_cedee.offre_cession.annonce.activite.substring(0, 100)}} ...
                </span>
                <span v-else>
                  {{cedant.entreprise_cedee.offre_cession.annonce.activite}}
                </span>
              </div>
              <p
                v-if="cedant.entreprise_cedee.offre_cession.annonce.is_published"
                class="gavc-p mt-2"
              >
                <span
                  v-if="cedant.entreprise_cedee.offre_cession.annonce.is_validated"
                  class="text-success"
                >
                  <i class="gavc-icon gavc-icon-check-circle"></i> Votre annonce est validée et publiée
                </span>
              </p>
              <p
                v-else
                class="gavc-p mt-2"
              >
                <span class="gavc-text-rose">
                  <i class="gavc-icon gavc-icon-cross"></i> Votre annonce n'est pas publiée
                </span>
              </p>
            </div>
            <p
              v-if="!cedant.entreprise_cedee.offre_cession.annonce.is_annonce_completed"
              class="gavc-legende gavc-text-rose gavc-margin-top"
            >
              Vous devez <strong><u>compléter votre annnonce</u></strong> avant de pouvoir la publier
            </p>
            <div class="gavc-card-offer__footer gavc-flex-align-between gavc-margin-top">
              <button
                v-if="cedant.entreprise_cedee.offre_cession.annonce.is_published && cedant.entreprise_cedee.offre_cession.annonce.is_validated"
                @click="$router.push(`/offres-de-cession/recherche?keywords=${cedant.entreprise_cedee.name}`)"
                class="gavc-btn">
                Visualiser mon annonce
              </button>
              <button
                v-else
                @click="$router.push(`/offres-de-cession/recherche`)"
                class="gavc-btn">
                Visualiser les annonces
              </button>
              <button
                v-if="!cedant.entreprise_cedee.offre_cession.annonce.is_published && cedant.entreprise_cedee.offre_cession.annonce.is_annonce_completed"
                class="gavc-btn"
                @click="modifyAnnoncePublicationStatus(true)"
              >
                Publier mon annonce
              </button>
              <button
                v-if="cedant.entreprise_cedee.offre_cession.annonce.is_published &&
                      cedant.entreprise_cedee.offre_cession.annonce.is_annonce_completed &&
                      !cedant.entreprise_cedee.offre_cession.annonce.is_validated"
                class="gavc-btn gavc-btn--bone"
                disabled
              >
                Annonce en cours <br> de validation
              </button>
            </div>

          </div>

            <div v-else class="gavc-card-offer gavc-padding-large">
              <div class="gavc-card-offer__header gavc-margin-bottom">
                <div>
                  <div class="gavc-question"> votre profil <span class="gavc-h1"> {{cedant.completed_rate}}% </span> </div>
                  <div class="gavc-card-progress-bar">
                    <div class="gavc-card-progress-bar gavc-card-progress-bar--active" :style="`width: ${cedant.completed_rate}%;`"></div>
                  </div>
                  <router-link v-if="cedant.completed_rate < 100" class="gavc-btn-secondary mt-2" to="/cedant/editer-mon-profil">
                    <span class="gavc-text-icon">Compléter mon profil</span>
                    <i class="gavc-icon gavc-icon-arrow-right"></i>
                  </router-link>
                </div>
                <div v-if="!cedant.is_personne_physique" class="mt-2">
                  <div class="gavc-question"> profil de votre société <span class="gavc-h1"> {{cedant.company.completed_rate}}% </span> </div>
                  <div class="gavc-card-progress-bar">
                    <div class="gavc-card-progress-bar gavc-card-progress-bar--active" :style="`width: ${cedant.company.completed_rate}%;`"></div>
                  </div>
                  <router-link v-if="cedant.company.completed_rate < 100" class="gavc-btn-secondary mt-2" to="/cedant/ma-societe">
                    <span class="gavc-text-icon">Compléter le profil de ma société</span>
                    <i class="gavc-icon gavc-icon-arrow-right"></i>
                  </router-link>
                </div>
                <div class="mt-2">
                  <div class="gavc-question"> profil de l'entreprise à céder <span class="gavc-h1"> {{cedant.entreprise_cedee.completed_rate}}% </span> </div>
                  <div class="gavc-card-progress-bar">
                    <div class="gavc-card-progress-bar gavc-card-progress-bar--active" :style="`width: ${cedant.entreprise_cedee.completed_rate}%;`"></div>
                  </div>
                  <router-link v-if="cedant.entreprise_cedee.completed_rate < 100" class="gavc-btn-secondary mt-2" to="/cedant/mon-entreprise-a-ceder">
                    <span class="gavc-text-icon">Compléter le profil de mon entreprise à céder</span>
                    <i class="gavc-icon gavc-icon-arrow-right"></i>
                  </router-link>
                </div>
              </div>
              <div class="gavc-card-offer__content gavc-padding-small">
                <h1 class="gavc-h1">Votre repreneur est là</h1>
                <p class="gavc-chapo">Une fois toutes vos informations renseignées, vous pouvez directement passer à l'étape des pourparlers !</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-3 mt-2 mb-2">
        <div class="col-12  offset-xl-1 ">
          <besoinAideComponent />
        </div>
      </div>
    </div>

    <div id="gavc-stepper-dashboard" class="gavc-bg-bizarre p-3">
      <ul class="gavc-stepper-dashboard" style="margin-top: 50px;">
        <li
          class="gavc-stepper-dashboard__item  gavc-text-center"
          v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 1, 'gavc-stepper-dashboard__item--is-active': activeStep === 1 }"
          >
          <p class="gavc-step-title">Annonce</p>
          <p class="gavc-step-number">1</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 2, 'gavc-stepper-dashboard__item--is-active': activeStep === 2 }">
          <p class="gavc-step-title">Candidature</p>
          <p class="gavc-step-number">2</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 3, 'gavc-stepper-dashboard__item--is-active': activeStep === 3 }">
          <p class="gavc-step-title">Pourparlers</p>
          <p class="gavc-step-number">3</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 4, 'gavc-stepper-dashboard__item--is-active': activeStep === 4 }">
          <p class="gavc-step-title">Audit</p>
          <p class="gavc-step-number">4</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 5, 'gavc-stepper-dashboard__item--is-active': activeStep === 5 }">
          <p class="gavc-step-title">Closing</p>
          <p class="gavc-step-number">5</p>
        </li>
      </ul>

      <div style="margin-top: 50px;">
        <span class="pr-3 gavc-h1 tk-congenial mt-4" style="border-right: 3px solid black;">
          Mes candidats
        </span>
        <span class="gavc-text-green ml-4">
          {{cedant.entreprise_cedee.offre_cession.candidatures.length}}
           <span v-if="cedant.entreprise_cedee.offre_cession.candidatures.length <= 1">
             candidature
           </span>
           <span v-else>
             candidatures
           </span>
        </span>

        <p class="gavc-chapo mt-4">
          Visualisez directement les repreneurs ayant candidaté à votre offre,
          et discutez de votre projet.
        </p>

        <div class="row mt-4" v-if="closings.length > 0">
          <div class="col-12 text-center">
            <span class="gavc-h3">CLOSING</span>
            <candidatureItem
              v-for="candidature in closings"
              :key="candidature.id"
              :candidature='candidature'
              :isProfileCompleted="isCedantInformationCompleted"
              step="CLOSING"
              v-on:details="seeCandidature(candidature)"
              v-on:go-to-step="seeClosing(candidature.closing)"
            />
          </div>
        </div>

        <div class="row mt-4" v-if="audits.length > 0">
          <div class="col-12 text-center">
            <span class="gavc-h3">AUDIT</span>
            <candidatureItem
              v-for="candidature in audits"
              :key="candidature.id"
              :candidature='candidature'
              :isProfileCompleted="isCedantInformationCompleted"
              step="AUDIT"
              v-on:details="seeCandidature(candidature)"
              v-on:go-to-step="seeAudit(candidature.audit)"
            />
          </div>
        </div>

        <div class="row mt-4" v-if="pourparlers.length > 0">
          <div class="col-12 text-center">
            <span class="gavc-h3">POURPARLERS</span>
            <candidatureItem
              v-for="candidature in pourparlers"
              :key="candidature.id"
              :candidature='candidature'
              :isProfileCompleted="isCedantInformationCompleted"
              step="POURPARLER"
              v-on:details="seeCandidature(candidature)"
              v-on:go-to-step="seePourparler(candidature.pourparler)"
            />
          </div>
        </div>

        <div class="row mt-4" v-if="candidatures.length > 0">
          <div class="col-12 text-center">
            <span class="gavc-h3">EN COURS</span>
            <candidatureItem
              v-for="candidature in candidatures"
              :key="candidature.id"
              :candidature='candidature'
              :isProfileCompleted="isCedantInformationCompleted"
              step="CANDIDATURE"
              v-on:details="seeCandidature(candidature)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  APICedant
} from '@/api/APICedant'
import {
  apiBaseUrl
} from '@/variables/localVariables'
import renderMixins from '@/mixins/renderMixins'

const apiCedantService = new APICedant()
const besoinAideComponent = () => import('@/components/layout/besoinAideComponent')
const candidatureFicheDetaillee = () => import('@/components/candidature/cedant/candidatureFicheDetaillee')
const candidatureItem = () => import('@/components/candidature/cedant/candidatureItem')

export default {
  name: 'CedantDashboard',
  components: {
    besoinAideComponent,
    candidatureFicheDetaillee,
    candidatureItem
  },
  mixins: [
    renderMixins
  ],
  data: function() {
    return {
      candidaturesRenderFields: [{
          key: "created_at",
          label: "Date",
          tdClass: 'ui-helper-center align-middle',
          sortable: true
        },
        {
          key: "full_name",
          label: "Candidat",
          tdClass: 'ui-helper-center align-middle',
          sortable: true
        },
        {
          key: "phone_number",
          label: "Téléphone",
          tdClass: 'ui-helper-center align-middle'
        },
        {
          key: "username",
          label: "E-mail",
          tdClass: 'ui-helper-center align-middle'
        },
        {
          key: "state",
          label: "Etat",
          tdClass: 'ui-helper-center align-middle',
          sortable: true
        },
      ],
      cedant: {
        entreprise_cedee: {
          address: '',
          capital: '',
          completed_rate: 0,
          forme_sociale: '',
          name: '',
          offre_cession: {
            annonce: {
              images: []
            },
            candidatures: []
          },
          phone_number: '',
          siren: ''
        },
        company: {
          completed_rate: 0
        },
        address: '',
        birthdate: '',
        city: '',
        completed_rate: 0,
        phone_number: '',
        user: {
          first_name: '',
          last_name: '',
          username: '',
        },
        zip_code: '',
      },
      handleCandidature: {
        description: '',
        id: '',
        repreneur: {
          phone_number: '',
          user: {
            first_name: '',
            last_name: '',
            username: ''
          },
        }
      },
      pourparlersRenderFields: [{
          key: "created_at",
          label: "Date",
          tdClass: 'ui-helper-center align-middle',
          sortable: true
        },
        {
          key: "full_name",
          label: "Candidat",
          tdClass: 'ui-helper-center align-middle',
          sortable: true
        },
        {
          key: "phone_number",
          label: "Téléphone",
          tdClass: 'ui-helper-center align-middle'
        },
        {
          key: "username",
          label: "E-mail",
          tdClass: 'ui-helper-center align-middle'
        },
        {
          key: "has_cedant_validated",
          label: "Validée par le cédant ?",
          tdClass: 'ui-helper-center align-middle',
          sortable: true
        },
        {
          key: "has_repreneur_validated",
          label: "Validée par le repreneur ?",
          tdClass: 'ui-helper-center align-middle',
          sortable: true
        },
      ],
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token
    },

    activeStep () {
      if (this.closings.length) {
        return 5
      }
      if (this.audits.length) {
        return 4
      }
      if (this.pourparlers.length) {
        return 3
      }
      if (this.candidatures.length) {
        return 2
      }
      return 1
    },
    audits() {
      const audits = []
      this.cedant.entreprise_cedee.offre_cession.candidatures.forEach((item) => {
        if (item.audit && !item.closing) {
          audits.push(item)
        }
      });
      return audits
    },
    candidatures() {
      const candidatures = []
      this.cedant.entreprise_cedee.offre_cession.candidatures.forEach((item) => {
        if (!item.audit && !item.pourparler && !item.closing) {
          candidatures.push(item)
        }
      });
      return candidatures
    },
    closings() {
      const closings = []
      this.cedant.entreprise_cedee.offre_cession.candidatures.forEach((item) => {
        if (item.closing) {
          closings.push(item)
        }
      });
      return closings
    },
    tauxCompletion () {
      let sum = this.cedant.completed_rate + this.cedant.entreprise_cedee.completed_rate
      let nb = 2
      if (!this.cedant.is_personne_physique) {
        sum = sum + this.cedant.company.completed_rate
        nb ++
      }
      if (this.cedant.entreprise_cedee.offre_cession.annonce) {
        sum = sum + this.cedant.entreprise_cedee.offre_cession.annonce.completed_rate
        nb ++
      }
      return parseFloat(sum / nb).toFixed(0)
    },
    isCedantInformationCompleted() {
      return this.cedant.completed_rate === 100 &&
             this.cedant.entreprise_cedee.completed_rate === 100 &&
             (this.cedant.is_personne_physique || (!this.cedant.is_personne_physique && this.cedant.company.completed_rate === 100)) &&
             (!this.cedant.entreprise_cedee.offre_cession.annonce ||
              (this.cedant.entreprise_cedee.offre_cession.annonce &&
               this.cedant.entreprise_cedee.offre_cession.annonce.completed_rate === 100
              )
             )
    },
    pourparlers() {
      const pourparlers = []
      this.cedant.entreprise_cedee.offre_cession.candidatures.forEach((item) => {
        if (item.pourparler && !item.audit) {
          pourparlers.push(item)
        }
      });
      return pourparlers
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getConnectedCedant()
  },
  methods: {
    getConnectedCedant() {
      this.$store.commit('loadFromAPI')
      apiCedantService.getConnectedCedant(this.token)
        .then((result) => {
          this.cedant = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },
    imageUrl(image) {
      return apiBaseUrl + image.file
    },
    modifyAnnoncePublicationStatus (publicationStatus) {
      this.$store.commit('loadFromAPI')
      var formData = new FormData()
      formData.append('is_published', publicationStatus);
      apiCedantService.modifyAnnonce(this.token, this.cedant.entreprise_cedee.offre_cession.annonce.id, formData)
      .then((result) => {
        this.cedant.entreprise_cedee.offre_cession.annonce = result.data
        if (this.cedant.entreprise_cedee.offre_cession.annonce.is_published) {
          this.$bvToast.toast('Félicitations, votre annonce a été publiée et est en cours de validation par nos équipes', {
            title: 'Offre de cession publiée',
            variant: 'success',
            solid: true
          })
        }
        else {
          this.$bvToast.toast('Votre annonce a bien été retirée', {
            title: 'Offre de cession retirée',
            variant: 'danger',
            solid: true
          })
        }
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('CedantDashboard publishAnnonce API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    seeCandidature(item) {
      this.handleCandidature = item
      this.$refs.handleCandidatureModal.show()
    },
    seePourparler(item) {
      this.$router.push(`/pourparler/${item.id}`)
    },
    seeAudit(item) {
      if (item.audit_step_ongoing === 3) {
        this.$router.push(`/audit/paiement-prix/${item.paiement_prix_id}`)
      }
      else if (item.audit_step_ongoing === 2) {
        this.$router.push(`/audit/entreprise-cedee/${item.id}`)
      }
      else {
        this.$router.push(`/audit/situation-juridique/${item.situation_juridique_id}`)
      }
    },
    seeClosing(item) {
      this.$router.push(`/closing/${item.id}`)
    },
    treatCandidature(state) {
      this.$store.commit('loadFromAPI')
      const data = {
        state: state
      }
      apiCedantService.modifyCandidature(this.token, this.handleCandidature.id, data)
        .then((result) => {
          this.getConnectedCedant()
          if (result.data.state == '2REFUSED') {
            this.$bvToast.toast(`Vous avez bien refusée la candidature de ${result.data.repreneur.user.first_name} ${result.data.repreneur.user.last_name}`, {
              title: 'Candidature refusée',
              variant: 'danger',
              solid: true
            })
          }
          if (result.data.state == '3VALIDATED') {
            this.$bvToast.toast(`Vous avez bien validé cette candidature. Vous pouvez maintenant accéder à l'étape Pourparlers`, {
              title: 'Candidature validée',
              variant: 'success',
              solid: true
            })
          }
          this.$refs.handleCandidatureModal.hide()
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('EditOffreCession publishOffreCession API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },
  }
}
</script>
