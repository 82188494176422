import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/se-connecter')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/se-connecter');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/se-connecter')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/se-connecter')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APICedant {

  createAnnonceImage (token, annonceId, data) {
    const url = apiBaseUrl + `/annonces/${annonceId}/images`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteAnnonceImage (token, annonceImageId) {
    const url = apiBaseUrl + `/annonces-images/${annonceImageId}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  getConnectedCedant(token) {
    const url = apiBaseUrl + `/connected-cedant-details`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getConnectedCedantCompany(token) {
    const url = apiBaseUrl + `/connected-cedant-company-details`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getConnectedEntrepriseCedee(token) {
    const url = apiBaseUrl + `/connected-entreprise-cedee-details`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAnnonce(token, annonceId) {
    const url = apiBaseUrl + `/annonce-details/${annonceId}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyCandidature (token, candidatureId,  data) {

    const url = apiBaseUrl + `/cedant-treat-candidature/${candidatureId}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyCedantCompany (token, data) {

    const url = apiBaseUrl + `/connected-cedant-company-details`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyEntrepriseCedee (token, data) {

    const url = apiBaseUrl + `/connected-entreprise-cedee-details`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyConnectedCedant (token, data) {

    const url = apiBaseUrl + `/connected-cedant-details`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyAnnonce (token, annonceId, data) {
    const url = apiBaseUrl + `/annonce-details/${annonceId}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }


    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }
}
